import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { isAuthenticated } from './services/auth';

const Login = lazy(() => import('./pages/Login'));
const Logoff = lazy(() => import('./pages/Logoff'));
const Signup = lazy(() => import('./pages/Signup'));
const Main = lazy(() => import('./pages/Main'));

const Companies = lazy(() => import('./pages/Company/Companies'));
const CompanyNew = lazy(() => import('./pages/Company/New'));
const CompanyEdit = lazy(() => import('./pages/Company/Edit'));

const Customers = lazy(() => import('./pages/Customer/Customers'));
const CustomerNew = lazy(() => import('./pages/Customer/New'));
const CustomerEdit = lazy(() => import('./pages/Customer/Edit'));

const Suppliers = lazy(() => import('./pages/Supplier/Suppliers'));
const SupplierNew = lazy(() => import('./pages/Supplier/New'));
const SupplierEdit = lazy(() => import('./pages/Supplier/Edit'));

const Employees = lazy(() => import('./pages/Employee/Employees'));
const EmployeeNew = lazy(() => import('./pages/Employee/New'));
const EmployeeEdit = lazy(() => import('./pages/Employee/Edit'));

const Accounts = lazy(() => import('./pages/Account/Accounts'));
const AccountNew = lazy(() => import('./pages/Account/New'));
const AccountEdit = lazy(() => import('./pages/Account/Edit'));

const Transactions = lazy(() => import('./pages/Transaction/Transactions'));
const TransactionNew = lazy(() => import('./pages/Transaction/New'));
const TransactionEdit = lazy(() => import('./pages/Transaction/Edit'));

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route 
        { ...rest }
        render={props =>
            isAuthenticated() ? (
                <Component { ...props } />
            ) : (
                <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            )
        }
    />
)

export default function Routes () {
    return (
        <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <Route path="/" exact component={Login} />
                    <Route path="/logoff" exact component={Logoff} />
                    <Route path="/signup" exact component={Signup} />
                    <PrivateRoute path="/Main" exact component={Main}/>

                    <PrivateRoute path="/companies" exact component={Companies} />
                    <PrivateRoute path="/companies/new" exact component={CompanyNew} />
                    <PrivateRoute path="/companies/:id" component={CompanyEdit} />

                    <PrivateRoute path="/customers" exact component={Customers} />
                    <PrivateRoute path="/customers/new" exact component={CustomerNew} />
                    <PrivateRoute path="/customers/:id" component={CustomerEdit} />

                    <PrivateRoute path="/suppliers" exact component={Suppliers} />
                    <PrivateRoute path="/suppliers/new" exact component={SupplierNew} />
                    <PrivateRoute path="/suppliers/:id" component={SupplierEdit} />

                    <PrivateRoute path="/employees" exact component={Employees} />
                    <PrivateRoute path="/employees/new" exact component={EmployeeNew} />
                    <PrivateRoute path="/employees/:id" component={EmployeeEdit} />

                    <PrivateRoute path="/accounts" exact component={Accounts} />
                    <PrivateRoute path="/accounts/new" exact component={AccountNew} />
                    <PrivateRoute path="/accounts/:id" component={AccountEdit} />

                    <PrivateRoute path="/transactions" exact component={Transactions} />
                    <PrivateRoute path="/transactions/new" exact component={TransactionNew} />
                    <PrivateRoute path="/transactions/:id" component={TransactionEdit} />
                </Switch>
            </Suspense>
        </BrowserRouter>
    )
}